import { Loop54Types, ShopTypes } from '~/lib/data-contract';

export interface EntitiesRequest extends Loop54Types.GetEntitiesRequest {
    resultsOptions?: EntityCollectionParameters;
}

export interface EntitiesByAttributeRequest extends Loop54Types.GetEntitiesByAttributeRequest {
    resultsOptions?: EntityCollectionParameters;
}

export interface RelatedEntitiesRequest extends Loop54Types.GetRelatedEntitiesRequest {
    resultsOptions?: EntityCollectionParameters;
}

export interface SearchRequest extends Loop54Types.SearchRequest {
    resultsOptions: EntityCollectionParameters;
}

interface EntityCollectionParameters extends Loop54Types.EntityCollectionParameters {
    filter?: any;
}

export type InverseFilterParameter = Loop54Types.InverseFilterParameter & {
    not:
        | Loop54Types.AttributeExistsFilterParameter
        | Loop54Types.AttributeFilterParameter
        | Loop54Types.InverseFilterParameter
        // Added below type options
        | Loop54Types.AndFilterParameter
        | Loop54Types.OrFilterParameter;
};

export type AndFilterParameter = Loop54Types.AndFilterParameter & {
    and: (
        | Loop54Types.AttributeExistsFilterParameter
        | Loop54Types.AttributeFilterParameter
        | Loop54Types.InverseFilterParameter
        // Added below type options
        | Loop54Types.AndFilterParameter
        | Loop54Types.OrFilterParameter
    )[];
};

export type OrFilterParameter = Loop54Types.OrFilterParameter & {
    or: (
        | Loop54Types.AttributeExistsFilterParameter
        | Loop54Types.AttributeFilterParameter
        | Loop54Types.InverseFilterParameter
        // Added below type options
        | Loop54Types.AndFilterParameter
        | Loop54Types.OrFilterParameter
    )[];
};

export enum Loop54ProductAttributes {
    AlternativePriceExclVat = 'AlternativePriceExclVat',
    AlternativePriceInclVat = 'AlternativePriceInclVat',
    Attribute_Values = 'Attribute_Values',
    Brand = 'Brand',
    CampaignLabelDescription = 'CampaignLabelDescription',
    Campaigns_Object = 'Campaigns_Object',
    CategoryLeaf = 'CategoryLeaf',
    CategoryPath = 'CategoryPath',
    ChannelNodeCode = 'ChannelNodeCode',
    ContextGroup = 'ContextGroup',
    ContextGroupDisplayName = 'ContextGroupDisplayName',
    Created = 'Created',
    DeliveryDaysMax = 'DeliveryDaysMax',
    DeliveryDaysMin = 'DeliveryDaysMin',
    EnergyClass = 'EnergyClass',
    EnergyLabelLink = 'EnergyLabelLink',
    EnergyProductDataSheetLink = 'EnergyProductDataSheetLink',
    Freight_Object = 'Freight_Object',
    HiddenKeywords = 'HiddenKeywords',
    HiddenKeyWords = 'HiddenKeyWords',
    Id = 'Id',
    ImageUrl = 'ImageUrl',
    InStock = 'InStock',
    ItemAmperes = 'ItemAmperes',
    ItemColor = 'ItemColor',
    ItemColorNuance = 'ItemColorNuance',
    ItemDepthCm = 'ItemDepthCm',
    ItemGrossWeight = 'ItemGrossWeight',
    ItemLengthCm = 'ItemLengthCm',
    ItemLengthMm = 'ItemLengthMm',
    ItemLiters = 'ItemLiters',
    ItemMainType = 'ItemMainType',
    ItemName = 'ItemName',
    ItemName2 = 'ItemName2',
    ItemSize = 'ItemSize',
    ItemSubType = 'ItemSubType',
    ItemSustainabiltyValues = 'ItemSustainabiltyValues',
    ItemThicknessMm = 'ItemThicknessMm',
    ItemUrl = 'ItemUrl',
    ItemVolts = 'ItemVolts',
    ItemWidthCm = 'ItemWidthCm',
    ItemWidthMm = 'ItemWidthMm',
    LongDescription = 'LongDescription',
    OriginalPriceExclVat = 'OriginalPriceExclVat',
    OriginalPriceInclVat = 'OriginalPriceInclVat',
    PriceExclVat = 'PriceExclVat',
    PriceInclVat = 'PriceInclVat',
    Priority = 'Priority',
    ProductAlternativeSalesUnit = 'ProductAlternativeSalesUnit',
    ProductAlternativeSalesUnitKey = 'ProductAlternativeSalesUnitKey',
    ProductAlternativeSalesUnitValue = 'ProductAlternativeSalesUnitValue',
    ProductAreasOfUse = 'ProductAreasOfUse',
    ProductBulbSocket = 'ProductBulbSocket',
    ProductDesigner = 'ProductDesigner',
    ProductId = 'ProductId',
    ProductMaterials = 'ProductMaterials',
    ProductName = 'ProductName',
    ProductName2 = 'ProductName2',
    ProductPrimaryShop = 'ProductPrimaryShop',
    ProductScrewHead = 'ProductScrewHead',
    ProductScrewTypes = 'ProductScrewTypes',
    ProductSKU = 'ProductSKU',
    ProductSurfaceTreatment = 'ProductSurfaceTreatment',
    ProductTreeCertification = 'ProductTreeCertification',
    ProductTreeImpregnation = 'ProductTreeImpregnation',
    ProductTreeSorting = 'ProductTreeSorting',
    ProductTreeSpecies = 'ProductTreeSpecies',
    Recommendations = 'Recommendations',
    SalesUnit = 'SalesUnit',
    SalesUnitKey = 'SalesUnitKey',
    SalesUnitValue = 'SalesUnitValue',
    ShortDescription = 'ShortDescription',
    SizeTypeSize = 'SizeTypeSize',
    SKU = 'SKU',
    sortOrder = 'sortOrder',
    SKUCount = 'SKUCount',
    Stocks_Object = 'Stocks_Object',
    StockStatus = 'StockStatus',
    SubBrand = 'SubBrand',
    SupplierArticleNumber = 'SupplierArticleNumber',
    SupplierEan = 'SupplierEan',
    VariantCount = 'VariantCount',
}

export type Loop54Product = {
    [Loop54ProductAttributes.AlternativePriceExclVat]: number;
    [Loop54ProductAttributes.AlternativePriceInclVat]: number;
    [Loop54ProductAttributes.Attribute_Values]: string;
    [Loop54ProductAttributes.Brand]: string;
    [Loop54ProductAttributes.Campaigns_Object]: Campaigns_Object[];
    [Loop54ProductAttributes.CategoryPath]: string;
    [Loop54ProductAttributes.ChannelNodeCode]: string;
    [Loop54ProductAttributes.ContextGroupDisplayName]: string;
    [Loop54ProductAttributes.ContextGroup]: string;
    [Loop54ProductAttributes.Created]: string;
    [Loop54ProductAttributes.DeliveryDaysMax]: number;
    [Loop54ProductAttributes.DeliveryDaysMin]: number;
    [Loop54ProductAttributes.EnergyClass]?: string;
    [Loop54ProductAttributes.EnergyLabelLink]?: string;
    [Loop54ProductAttributes.EnergyProductDataSheetLink]?: string;
    [Loop54ProductAttributes.Freight_Object]: Freight_Object[];
    [Loop54ProductAttributes.HiddenKeywords]: string;
    [Loop54ProductAttributes.Id]: string;
    [Loop54ProductAttributes.ImageUrl]: string;
    [Loop54ProductAttributes.InStock]: boolean;
    [Loop54ProductAttributes.ItemAmperes]: string;
    [Loop54ProductAttributes.ItemColor]: string;
    [Loop54ProductAttributes.ItemColorNuance]: string;
    [Loop54ProductAttributes.ItemDepthCm]: number;
    [Loop54ProductAttributes.ItemGrossWeight]: number;
    [Loop54ProductAttributes.ItemLengthCm]: number;
    [Loop54ProductAttributes.ItemLengthMm]: number;
    [Loop54ProductAttributes.ItemLiters]: string;
    [Loop54ProductAttributes.ItemMainType]: string;
    [Loop54ProductAttributes.ItemName]: string;
    [Loop54ProductAttributes.ItemName2]: string;
    [Loop54ProductAttributes.ItemSize]: string;
    [Loop54ProductAttributes.ItemSubType]: string;
    [Loop54ProductAttributes.ItemThicknessMm]: number;
    [Loop54ProductAttributes.ItemUrl]: string;
    [Loop54ProductAttributes.ItemVolts]: string;
    [Loop54ProductAttributes.ItemWidthCm]: number;
    [Loop54ProductAttributes.ItemWidthMm]: number;
    [Loop54ProductAttributes.LongDescription]: string;
    [Loop54ProductAttributes.OriginalPriceExclVat]: number;
    [Loop54ProductAttributes.OriginalPriceInclVat]: number;
    [Loop54ProductAttributes.PriceExclVat]: number;
    [Loop54ProductAttributes.PriceInclVat]: number;
    [Loop54ProductAttributes.Priority]: number;
    [Loop54ProductAttributes.ProductAlternativeSalesUnit]: string;
    [Loop54ProductAttributes.ProductAlternativeSalesUnitKey]: string;
    [Loop54ProductAttributes.ProductAlternativeSalesUnitValue]: string;
    [Loop54ProductAttributes.ProductAreasOfUse]: string;
    [Loop54ProductAttributes.ProductBulbSocket]: string;
    [Loop54ProductAttributes.ProductDesigner]: string;
    [Loop54ProductAttributes.ProductId]: string;
    [Loop54ProductAttributes.ProductMaterials]: string;
    [Loop54ProductAttributes.ProductName]: string;
    [Loop54ProductAttributes.ProductName2]: string;
    [Loop54ProductAttributes.ProductPrimaryShop]: string;
    [Loop54ProductAttributes.ProductScrewHead]: string;
    [Loop54ProductAttributes.ProductScrewTypes]: string;
    [Loop54ProductAttributes.ProductSKU]: string;
    [Loop54ProductAttributes.ProductSurfaceTreatment]: string;
    [Loop54ProductAttributes.ProductTreeCertification]: string;
    [Loop54ProductAttributes.ProductTreeImpregnation]: string;
    [Loop54ProductAttributes.ProductTreeSorting]: string;
    [Loop54ProductAttributes.ProductTreeSpecies]: string;
    [Loop54ProductAttributes.Recommendations]: string;
    [Loop54ProductAttributes.SalesUnit]: string;
    [Loop54ProductAttributes.SalesUnitKey]: string;
    [Loop54ProductAttributes.SalesUnitValue]: string;
    [Loop54ProductAttributes.ShortDescription]: string;
    [Loop54ProductAttributes.SizeTypeSize]: string;
    [Loop54ProductAttributes.SKU]: string;
    [Loop54ProductAttributes.sortOrder]: string;
    [Loop54ProductAttributes.SKUCount]: number;
    [Loop54ProductAttributes.Stocks_Object]: Stocks_Object[];
    [Loop54ProductAttributes.StockStatus]: ShopTypes.StockStatus;
    [Loop54ProductAttributes.SubBrand]: string;
    [Loop54ProductAttributes.SupplierArticleNumber]: string;
    [Loop54ProductAttributes.SupplierEan]: string;
    [Loop54ProductAttributes.VariantCount]: string;
};

// Filter defaults
export interface FilterDefaultsResponse {
    defaultFacets: DefaultFacets;
    defaultSortOptions: SortOptions;
    facets: Facets;
    sortOptions: SortOptions;
}

export type DefaultFacets = {
    [key in Loop54ProductAttributes]: Facet;
};

export type Facet = {
    key: Loop54ProductAttributes;
    type: 'distinct' | 'range';
};

export type Facets = {
    [key in Loop54ProductAttributes]: Facet;
};

export type SortOptions = {
    [key: string]: {
        key: string;
        sorts: SortOption[];
    };
};

export type SortOption = {
    attributeName?: Loop54ProductAttributes;
    order?: 'asc' | 'desc';
    type: 'attribute' | 'id' | 'type' | 'relevance' | 'popularity';
};

export type Freight_Object = {
    FreightId: number;
    FreightName: string;
    FreightPriceExclVat: number;
    FreightPriceInclVat: number;
    FreightType: ShopTypes.FreightType;
};

export type Stocks_Object = {
    WarehouseId: string;
    Quantity: number;
};

export type Campaigns_Object = {
    CampaignPriceExclVat: number | null;
    CampaignPriceInclVat: number | null;
    CampaignName: string;
    CampaignStartDate: Date;
    CampaignEndDate: Date;
    CampaignDescription: string | null;
    CampaignPriority: number;
    CampaignTypes: string[];
    CampaignFreeDelivery: string | null;
    CampaignLabelDescription: string;
    CampaignFontColorCode: string; // Hex value
    CampaignLabelColorCode: string; // Hex value
    SalesUnitKey: string;
    SalesUnitValue: string;
    CampaignId: number;
    CampaignNormalPriceExclVat: number | null;
    CampaignNormalPriceInclVat: number | null;
};
